<template>
  <div >
    <h1>Rezept für Forellen einlegen</h1>
    <p>
      Für dieses Rezept gilt 1 Forelle wiegt etwa 400g.
    </p>
    <h3>Anzahl Forellen</h3>
    <input v-model="anzahlForellen" type="number" min="0">

    <h3>Zutaten</h3>
    <p>{{ anzahlForellen * 1.5 }}l Wasser</p>
    <p>{{ anzahlForellen * 90 }}g Salz</p>
    <p>{{ anzahlForellen * 3 }}g Wacholder Beeren (zerdrückt)</p>
    <p>{{ anzahlForellen * 1 }}g Kräuter der Provence</p>
    
    <h3>Zubereitung</h3>
    <p>
      Salz muss komplett aufgelöst sein.
      Forellen mindestens für 12 Stunden darin einlegen.
    </p>

    <router-link type="submit" class="btn btn-primary" to="/" tag="button">Zur Hauptseite</router-link>

  </div>

  
</template>

<script>


export default {
  name: 'ForellenRezept',
  data() {
    return {
        anzahlForellen: String
    }
  },
  created() {
      this.anzahlForellen = 10;
      console.log(this.anzahlForellen)
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
