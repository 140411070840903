<template>
  <div >
    <h1>haetty-gern.de</h1>
    <p>
      Die Hätty Brüder Daniel und Michael
    </p>
    <router-link type="submit" class="btn btn-primary" to="/rezept" tag="button">Zum Rezept</router-link>

    
<p> </p>
    <img class="img-fluid" src="brueder.jpg">
    
  </div>

  
</template>

<script>


export default {
  name: 'WelcomeHaettyGern',
  data() {
    return {
        
    }
  },
  created() {
      
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
